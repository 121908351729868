import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';
import styled from "styled-components";



const Container = styled.div`
  max-width: ${({ theme }) => theme.size.tablet}px;
  margin: auto;
  text-align: center;
`;

const LVPage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html;

  return (
    <>
      <SEO
        canonical="/lv/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <br />

          <Container>
            <PageHTML center dangerouslySetInnerHTML={{ __html: html }} />
          </Container>

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}
export default LVPage;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/lv.md/"}) {
    frontmatter {
      title
    }
    html
  }
}  
`;

